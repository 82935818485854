import * as React from "react"
import VerificationCompletePage from "./Verification/VerificationCompletePage";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "'Open Sans', sans-serif",
  backgroundColor: '#18182a',
  position: 'relative',
}

const VerificationComplete = () => {
  return (
    <main style={pageStyles}>
      <VerificationCompletePage />
    </main>
  )
}

export default VerificationComplete
